@import url("https://cdn.syncfusion.com/ej2/material.css");

table,
th,
td {
  min-height: 12vh;
  border: 1px solid black;
  align-items: center;
}
table {
  /* width: 97.75vw; */
  width: 100%;
  table-layout: fixed;
}
td {
  text-align: center;
  overflow: hidden;
}
