.wrapper {
  display: flex;
  width: 100%;
  align-items: flex-start;
  justify-content: space-between;
  height: 100vh;
}

#sidebar {
  min-width: 15vw;
  max-width: 20vw;
  background: #212224;
  color: #fff;
  transition: all 0.3s;
  overflow-y: scroll;
  height: 100vh;
}
#projects {
  min-width: 15vw;
  max-width: 20vw;
  background: #7386d5;
  color: #fff;
  transition: all 0.3s;
  overflow-y: scroll;
  height: 100vh;
}

#content {
  width: 40vw;
  padding: 20px;
  transition: all 0.3s;
  text-align: center;
  background: #999a9c;
  margin-top: 10vw;
}
#managers {
  min-width: 15vw;
  max-width: 20vw;
  background: #999a9c;
  color: #fff;
  transition: all 0.3s;
  overflow-y: scroll;
  height: 100vh;
  align-items: center;
}
