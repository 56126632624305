/* .invoiceList :active {
  background-color: black;
}
.container {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  align-items: flex-start;
  height: 100vh;
}
.sidebar {
  min-width: 20vw;
  transition: all 0.3s;
  overflow-y: scroll;
  height: 82vh;
  flex-direction: column;
}

.details {
  width: 50vw;
  padding: 20px;
  transition: all 0.3s;
  text-align: center;
}

.detailsTable > tr > td {
  height: fit-content;
}
.folderView {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 50vw;
}
.detailsTable {
  border-width: 0;
  margin-bottom: 0;
} */


.invoiceList :active {
  background-color: black;
}
.container {
  display: flex;
  width: 100%;
  align-items: flex-start;
  justify-content: space-between;
  height: 100vh;
}
.sidebar {
  min-width: 20vw;

  transition: all 0.3s;
  overflow-y: scroll;
  height: 82vh;
  flex-direction: column;
}

.details {
  width: 50vw;
  padding: 20px;
  transition: all 0.3s;
  text-align: center;
}

.detailsTable > tr > td {
  height: fit-content;
}
.folderView {
  width: 25vw;
}
.detailsTable {
  border-width: 0;
  margin-bottom: 0;
}