.nav {
  /* position: fixed;
  top: 0;
  width: 100%;
  display: flex;
  height: 30px;
  z-index: 1;
  padding: 20px; */

  height: 60px;
  display: flex;
  align-items: center;
  background-color: #0e1620;
  position: sticky;
  top: 0;
  z-index: 1;
  width: 100%;
  flex: 1;
  justify-content: space-evenly;
}

.nav__logo {
  position: fixed;
  width: 100px;
  object-fit: contain;
  align-self: center;
}

.header {
  height: 60px;
  display: flex;
  align-items: center;
  background-color: #131921;
  position: sticky;
  top: 0;
  z-index: 1;
  width: 100%;
  flex: 1;
  justify-content:space-between;
}
.header__logo {
  width: 100px;
  object-fit: contain;
  margin: 0 20px;
  margin: 18px;
}
.header__search {
  display: flex;
  flex: 1;
  align-items: center;
  border-radius: 24px;
}

.header__searchInput {
  height: 12px;
  padding: 10px;
  border: none;
  width: 100%;
}

.header__searchIcon {
  padding: 5px;
  height: 22px !important;
  background-color: #cd9042;
}

.header__optionLineOne {
  font-size: 10px;
}
.header__optionLineTwo {
  font-size: 13px;
  font-weight: 800;
}

.header__option {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  margin-right: 10px;
  color: white;
}
.header__nav {
  display: flex;
  justify-content: space-evenly;
}
.header__optionBasket {
  display: flex;
  align-items: center;
  color: white;
}
.header__basketCount {
  margin-left: 10px;
  margin-right: 10px;
}
