@import url(https://cdn.syncfusion.com/ej2/material.css);
* {
  margin: 0;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.app {
  background-color: rgb(20, 90, 131);
  height: "100vh";
}

.login {
  background-color: white;
  height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
}
.login__logo {
  margin-top: 20px;
  margin-bottom: 20px;
  object-fit: contain;
  width: 100px;
  margin-right: auto;
  margin-left: auto;
}
.login__container {
  width: 300px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  border-radius: 5px;
  border: 1px solid lightgray;
  padding: 20px;
}
.login__container > h1 {
  font-weight: 500;
  margin-bottom: 20px;
}

.login__container > form > h5 {
  margin-bottom: 5px;
}

.login__container > form > input {
  height: 30px;
  margin-bottom: 10px;
  background-color: white;
  width: 98%;
}

.login__container > p {
  margin-top: 15px;
  font-size: 12px;
}

.login__signInButton {
  background: #0F72B0;
  border-radius: 2px;
  width: 100%;
  height: 30px;
  border: 1px solid;
  margin-top: 10px;
  border-color: #a88734 #9c7e31 #846a29;
}

.login__register {
  border-radius: 2px;
  width: 100%;
  height: 30%;
  border: 1px solid;
  margin-top: 10px;
  border-color: darkgray;
}

table,
th,
td {
  min-height: 12vh;
  border: 1px solid black;
  -webkit-align-items: center;
          align-items: center;
}
table {
  /* width: 97.75vw; */
  width: 100%;
  table-layout: fixed;
}
td {
  text-align: center;
  overflow: hidden;
}

.nav {
  /* position: fixed;
  top: 0;
  width: 100%;
  display: flex;
  height: 30px;
  z-index: 1;
  padding: 20px; */

  height: 60px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  background-color: #0e1620;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1;
  width: 100%;
  -webkit-flex: 1 1;
          flex: 1 1;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
}

.nav__logo {
  position: fixed;
  width: 100px;
  object-fit: contain;
  -webkit-align-self: center;
          align-self: center;
}

.header {
  height: 60px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  background-color: #131921;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1;
  width: 100%;
  -webkit-flex: 1 1;
          flex: 1 1;
  -webkit-justify-content:space-between;
          justify-content:space-between;
}
.header__logo {
  width: 100px;
  object-fit: contain;
  margin: 0 20px;
  margin: 18px;
}
.header__search {
  display: -webkit-flex;
  display: flex;
  -webkit-flex: 1 1;
          flex: 1 1;
  -webkit-align-items: center;
          align-items: center;
  border-radius: 24px;
}

.header__searchInput {
  height: 12px;
  padding: 10px;
  border: none;
  width: 100%;
}

.header__searchIcon {
  padding: 5px;
  height: 22px !important;
  background-color: #cd9042;
}

.header__optionLineOne {
  font-size: 10px;
}
.header__optionLineTwo {
  font-size: 13px;
  font-weight: 800;
}

.header__option {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin-left: 10px;
  margin-right: 10px;
  color: white;
}
.header__nav {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
}
.header__optionBasket {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  color: white;
}
.header__basketCount {
  margin-left: 10px;
  margin-right: 10px;
}

/* .invoiceList :active {
  background-color: black;
}
.container {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  align-items: flex-start;
  height: 100vh;
}
.sidebar {
  min-width: 20vw;
  transition: all 0.3s;
  overflow-y: scroll;
  height: 82vh;
  flex-direction: column;
}

.details {
  width: 50vw;
  padding: 20px;
  transition: all 0.3s;
  text-align: center;
}

.detailsTable > tr > td {
  height: fit-content;
}
.folderView {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 50vw;
}
.detailsTable {
  border-width: 0;
  margin-bottom: 0;
} */


.invoiceList :active {
  background-color: black;
}
.container {
  display: -webkit-flex;
  display: flex;
  width: 100%;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  height: 100vh;
}
.sidebar {
  min-width: 20vw;

  transition: all 0.3s;
  overflow-y: scroll;
  height: 82vh;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.details {
  width: 50vw;
  padding: 20px;
  transition: all 0.3s;
  text-align: center;
}

.detailsTable > tr > td {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}
.folderView {
  width: 25vw;
}
.detailsTable {
  border-width: 0;
  margin-bottom: 0;
}
.wrapper {
  display: -webkit-flex;
  display: flex;
  width: 100%;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  height: 100vh;
}

#sidebar {
  min-width: 15vw;
  max-width: 20vw;
  background: #212224;
  color: #fff;
  transition: all 0.3s;
  overflow-y: scroll;
  height: 100vh;
}
#projects {
  min-width: 15vw;
  max-width: 20vw;
  background: #7386d5;
  color: #fff;
  transition: all 0.3s;
  overflow-y: scroll;
  height: 100vh;
}

#content {
  width: 40vw;
  padding: 20px;
  transition: all 0.3s;
  text-align: center;
  background: #999a9c;
  margin-top: 10vw;
}
#managers {
  min-width: 15vw;
  max-width: 20vw;
  background: #999a9c;
  color: #fff;
  transition: all 0.3s;
  overflow-y: scroll;
  height: 100vh;
  -webkit-align-items: center;
          align-items: center;
}

